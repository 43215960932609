import React from 'react';
import Cards from 'components/Core/Cards';

const PressCardsSection = ({ backgroundImg, title, description, pressData, ...otherProps }) => (
  <div className="bg-white -mt-40" {...otherProps}>
    <div className="z-1 relative py-7 px-10 lg:py-7 lg:px-10">
      {pressData.map((item, index) => {
        return (
          <div className="bg-white max-w-[1195px] mt-7 mx-auto mb-0 lg:mt-5" key={index}>
            <Cards
              bodyType="simple"
              category={item.node.postDate}
              title={item.node.postTitle || ''}
              description={item.node?.seo?.metaDescription?.metaDescription || ''}
              ctaTitle="Learn more"
              ctaLink={item?.node?.seo?.pageUrl}
            />
          </div>
        );
      })}
    </div>
  </div>
);

export default PressCardsSection;
