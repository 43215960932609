import React from 'react';
import { twMerge } from 'tailwind-merge';
import Button from 'components/Core/Button';
import { Col, Row } from 'components/Core/Grid';
import useBreakpointView from 'utils/useBreakpointView';
import prevArrow from '../../../../static/images/pagination-left-arrow.svg';
import nextArrow from '../../../../static/images/pagination-right-arrow.svg';
import './Pagination.less';

const Pagination = ({
  pageContext = { currentPage: 1, numPages: 1 },
  template = '',
  anchorId = '',
  onPage,
  className,
}) => {
  //pagination
  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const formatUrl = (page) =>
    `/${template}/${page === 1 ? '' : 'page/' + page.toString()}${anchorId ? '#' + anchorId : ''}`;
  const prevPage = formatUrl(currentPage - 1);
  const nextPage = formatUrl(currentPage + 1);
  const isMobile = useBreakpointView(['xs', 'sm']);

  // A sweet helper function to create pagination object
  const createPaginationObjects = (length, page, increment = 2) =>
    Array.from({ length }, (_, i) => ({
      link: formatUrl(i + increment),
      index: i + increment,
      current: page === i + increment,
    }));

  // Create the navigation link
  const navItems = [
    {
      link: formatUrl(1),
      index: 1,
      current: currentPage === isFirst,
    },
  ];
  if (numPages <= 5) {
    navItems.push(
      ...Array.from({ length: numPages - 1 }, (_, i) => ({
        link: formatUrl(i + 2),
        index: i + 2,
        current: currentPage === i + 2,
      })),
    );
  } else {
    // We have a situation where we have to show the first
    // item, three items around the current one
    // and also the last item
    /* eslint-disable no-lonely-if */
    if (currentPage <= 3) {
      // If the current one is closer to the start
      navItems.push(
        ...createPaginationObjects(isMobile ? 2 : 3, currentPage),
        {
          separator: true,
          index: 'finish-separator',
        },
        {
          link: formatUrl(numPages),
          index: numPages,
          current: false,
        },
      );
    } else if (currentPage > numPages - 3) {
      // If the current one is closer to the last one
      navItems.push(
        {
          separator: true,
          index: 'starter-separator',
        },
        ...createPaginationObjects(isMobile ? 3 : 4, currentPage, numPages - (isMobile ? 2 : 3)),
      );
    } else {
      navItems.push(
        {
          separator: true,
          index: 'starter-separator',
        },
        ...createPaginationObjects(isMobile ? 1 : 3, currentPage, currentPage - (isMobile ? 0 : 1)),
        {
          separator: true,
          index: 'finisher-separator',
        },
        {
          link: formatUrl(numPages),
          index: numPages,
          current: false,
        },
      );
    }
    /* eslint-enable */
  }

  const getSeparatorPage = (separatorType) => {
    if (isFirst) {
      return currentPage + (isMobile ? 3 : 5);
    } else if (isLast) {
      return currentPage - (isMobile ? 3 : 5);
    } else {
      return separatorType === 'starter-separator'
        ? currentPage - (isMobile ? 2 : 3)
        : currentPage + (isMobile ? 2 : 3);
    }
  };

  return (
    <Row
      className={twMerge('pagination-row', className)}
      type="flex"
      justify="center"
      align="center"
    >
      {!isFirst && (
        <Col>
          <div
            className="pag--name"
            style={{ textAlign: 'center', marginTop: 55 }}
            data-position={'Pagination - CTA to ' + prevPage}
          >
            <Button
              className="prev--btn"
              type="Transparent - Black Border"
              size="big"
              to={prevPage}
              rel="prev"
              shadow={false}
              onClick={!onPage ? null : (e) => onPage(currentPage - 1, e)}
            >
              <img src={prevArrow} />
              <span>prev</span>
            </Button>
          </div>
        </Col>
      )}
      {navItems.map((item, index) => (
        <Col key={index}>
          {item.separator ? (
            <Button
              to={formatUrl(getSeparatorPage(item.index))}
              type="transparent - black text - no border"
              className="sp--box"
              onClick={(e) => (!onPage ? null : onPage(getSeparatorPage(item.index), e))}
            />
          ) : (
            <div
              className="pag--name"
              style={{ textAlign: 'center', marginTop: 55 }}
              key={`pagination-number-${item.index}`}
              data-position={'Pagination - CTA to ' + item.link}
            >
              <Button
                className="pag--num"
                type={
                  currentPage === item.index ? 'tertiary' : 'Transparent - Black Text - No Border'
                }
                size="big"
                to={item.link}
                shadow={false}
                onClick={!onPage ? null : (e) => onPage(item.index, e)}
              >
                {item.index}
              </Button>
            </div>
          )}
        </Col>
      ))}
      {!isLast && (
        <Col>
          <div
            className="pag--name mx-0"
            style={{ textAlign: 'center', marginTop: 55 }}
            data-position={'Pagination - CTA to ' + nextPage}
          >
            <Button
              className="pag--name next--name"
              type="Transparent - Black Border"
              size="big"
              to={!onPage ? nextPage : undefined}
              rel="next"
              shadow={false}
              onClick={!onPage ? null : (e) => onPage(currentPage + 1, e)}
            >
              <span>Next</span>
              <img src={nextArrow} />
            </Button>
          </div>
        </Col>
      )}
    </Row>
  );
};
export default Pagination;
