import React from 'react';
import { graphql } from 'gatsby';
import PressCardsSection from 'components/Page/Press/PressCardsSection';
import Pagination from 'components/Core/Pagination';
import ContentfulRowContent from 'components/Contentful/ContentfulRowContent';
import SEO, { createSEO } from 'components/Core/SEO';
import WebsiteLayout from 'layouts/websiteLayout';
import ContentfulDynamicHero from 'components/Contentful/ContentfulDynamicHero';

const PressTemplate = (props) => {
  const { components } = props.data.contentfulComponentCollection;
  const fetchEntries = (filter) => components.find((ele) => ele.__typename === filter);

  const contentfulSeo = fetchEntries('ContentfulSeo');
  const { edges } = props.data.allContentfulPress;

  const seo = createSEO(
    {
      ...contentfulSeo,
      canonicalUrl: props.pageContext.currentPage > 1 ? null : contentfulSeo?.canonicalUrl,
    },
    props,
  );

  return (
    <WebsiteLayout hero={fetchEntries('ContentfulDynamicHero')} headerTheme="TRANS_BG_WHITE_COLOR">
      <SEO {...seo} />
      <div>
        <ContentfulDynamicHero
          className="pb-20 sm-max:pb-[100px]"
          {...fetchEntries('ContentfulDynamicHero')}
        />
        <PressCardsSection pressData={edges} />
        <Pagination className="sm-max:!pb-[50px]" template="press" pageContext={props.pageContext} />
        <ContentfulRowContent {...fetchEntries('ContentfulRowContent')} />
      </div>
    </WebsiteLayout>
  );
};

export default PressTemplate;

export const PressTemplateQuery = graphql`
  query PressTemplateQuery($skip: Int!, $limit: Int!) {
    contentfulComponentCollection(contentful_id: { eq: "8BrxvJw6GczBdJAHrQ8nz" }) {
      ...ComponentCollection
    }
    allContentfulPress(limit: $limit, skip: $skip, sort: { order: DESC, fields: postDate }) {
      edges {
        node {
          id
          updatedAt
          seo {
            ...Seo
          }
          postTitle
          postDate(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`;
